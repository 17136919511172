<template>
  <div>
    <div class="card-content">
      <div class="content" style="display: grid; justify-content: center">
        <div style="width:500px;">
          <label class="label" for="">Payment plan ID:</label>
          <input type="text" class="input" v-model="enteredPaymentPlanId">
          <label class="label" for="">Confirm payment plan ID:</label>
          <input type="text" class="input" v-model="confirmPaymentPlanId">
          <p v-if="disableCancel" style="width: 100%; color: red; font-weight: 700;">
              {{disableCancelReason}}
          </p>
          <button class="button is-primary"
                  style="margin-top: 8px;"
                  :disabled="disableCancel"
                  @click="showCancelPrompt">
            Cancel payment plan
          </button>

          <p v-if="showResponse" :style="responseMessageStyle">{{getResponseMessage}}</p>

          <p style="font-weight: 600;">
            ⓘ This action will cancel future
             instalments for the payment plan.
             This action is irreversible.</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>


export default {
  components: { },
  data() {
    const { paymentPlanId, tenant } = this.$route.params;
    return {
      paymentPlanId,
      tenant,
      enteredPaymentPlanId: '',
      confirmPaymentPlanId: '',
      responseMessage: '',
      responseStatus: -1,
    };
  },
  props: {
    paymentPlan: Object,
  },
  methods: {
    setResponseMessage(response, message) {
      this.responseStatus = response.status;
      this.responseMessage = message;
    },
    showCancelPrompt() {
      if (confirm('Are you sure you would like to cancel this payment plan?')){ //eslint-disable-line
        const dataService = this.$dataService(this.tenant);
        dataService.paymentPlans.cancel(this.paymentPlanId)
          .then((response) => {
            this.setResponseMessage(response, 'Success');
          })
          .catch((error) => {
            this.setResponseMessage(error, 'Error');
          });
      }
    },
  },
  computed:
  {
    disableCancelReason() {
      if (this.enteredPaymentPlanId === '') { return ''; }
      if (this.enteredPaymentPlanId !== this.confirmPaymentPlanId) { return 'IDs do not match.'; }
      if (this.enteredPaymentPlanId !== this.paymentPlanId) { return 'ID must match payment plan ID'; }

      return '';
    },
    disableCancel() {
      return this.enteredPaymentPlanId !== this.confirmPaymentPlanId || this.enteredPaymentPlanId === '' || this.enteredPaymentPlanId !== this.paymentPlanId;
    },
    showResponse() {
      return this.responseStatus !== -1;
    },
    getResponseMessage() {
      return `${this.responseStatus}: ${this.responseMessage}`;
    },
    responseMessageStyle() {
      return `font-weight: 700; color:${this.responseStatus === 204 ? 'green' : 'red'};`;
    },
  },
};
</script>
